/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:f64ff65b-cd53-4a31-b720-531245280f7e",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_Zdj929AgD",
    "aws_user_pools_web_client_id": "6hkab40hv1bvvevjtl57f6d11u",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://yye423alyvfixjevnorixxwnim.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-cogfc6q3bfbjpfyokpqfjnhup4",
    "aws_cloud_logic_custom": [
        {
            "name": "AppointmentUpdate",
            "endpoint": "https://0ntcdcyly3.execute-api.ap-southeast-2.amazonaws.com/test",
            "region": "ap-southeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "aspirecare76868a15c6c048e3a840d5436706ba22125748-test",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
