import React from "react"
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from "mdbreact"
import "./carousel.css"

const CarouselPage = ({imgList}) => {
  return (
    <MDBCarousel activeItem={1} length={imgList.length}>
      <MDBCarouselInner>
        {imgList.map(item => 
          <MDBCarouselItem itemId={item.key} key={item.key}>
            <MDBView>
              {/* <img 
                src={item.image.images.fallback.src} 
                srcSet={item.image.images.sources[0].srcSet} 
                alt={item.title} 
              /> */}
              <img className="d-block w-100" src={item.name} />
              <MDBMask overlay="black-strong" />
            </MDBView>
          </MDBCarouselItem>
        )}
      </MDBCarouselInner>
    </MDBCarousel>
  )
}

export default CarouselPage
