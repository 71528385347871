import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Storage } from 'aws-amplify'
import axios from 'axios'

const useStyles = makeStyles(theme => ({
  close: {
    justifyContent: "flex-end"
  }  
}))

export default function Bulletin({theme}) {
  const [open, setOpen] = useState(false)
  const [noticeUrl, setNoticeUrl] = useState('')
  const [html, setHtml] = useState('')
  
  const classes = useStyles(theme)

  useEffect(() => {
    Storage.get('notice.html')
      .then(data => {
        setNoticeUrl(data)
      })
      .catch(err => {
        console.error('error fetching notice.html')
      })
  }, [])

  useEffect(() => {
    const fetchNotice = async () => {
      if (noticeUrl) {
        try {
          const config = {
            method: 'get',
            headers: {
              "Content-Type": "application/json"
            },
            url: noticeUrl
          }
          const result = await axios(config)
          setHtml(result.data)
          setOpen(true)
        }
        catch (err) {
          console.error('error reading notice.html')
        }
      }
    }

    fetchNotice()

  }, [noticeUrl])

  const closeDialog = () => {
    setOpen(false)
  }

  return (
    <>
      <Dialog open={open} onBackdropClick={closeDialog}>
        <IconButton  id="close-notice" edge="start" color="inherit" className={classes.close} onClick={closeDialog} aria-label="close">
          <CloseIcon/>
        </IconButton> 
        <DialogContent>    
          <div style={{fontSize: "large"}} dangerouslySetInnerHTML={{ __html: html }} />
        </DialogContent>
      </Dialog>
    </>
  )
}