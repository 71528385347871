import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from "mdbreact"
import './intro.css'
import { aspireMCPhoneNo, aspireMCPhone } from "../utils/booking-api"
// import wallpaper from "../images/home.jpg"

const Intro = ({ children }) => {
  return (
    <div>
    {/* <div style={{  */
    /* //   width: '100%', 
    //   height: '100%', 
    //   backgroundImage: `Url(${wallpaper})`, 
    //   backgroundSize: 'cover', 
    //   backgroundPosition: '50% 20%',
    //   backgroundRepeat: 'no-repeat'}}> */}
      <MDBContainer style={{paddingTop: "15vh", height: "85vh"}}>
        <MDBRow >
          <MDBCol md="12" className="white-text text-center">
            {/* <h1 className="h1-responsive font-weight-bold mb-0 pt-md-5 pt-5">{`Call us now: ${aspireMCPhone}`}</h1> */}
            <div className="text-center">
              <i className="fas fa-phone-alt mr-2 h3-responsive"></i>
              <a href={`tel:${aspireMCPhoneNo}`} className="h2-responsive white-text font-weight-bold">
                {`Call us now: ${aspireMCPhone}`}
              </a>
            </div>  
            <hr className="hr-light my-4 w-90" />
            <div className="text-center">
              <i className="fas fa-map-marker-alt mr-2 h3-responsive"></i>
              <a href='https://www.google.com/maps/place/2+Hillview+Rd,+Eastwood+NSW+2122/@-33.7904974,151.0815816,15z/data=!4m5!3m4!1s0x6b12a439e4dfcc77:0x3184686087efe006!8m2!3d-33.790906!4d151.0815375' 
                alt="" target='_blank' rel="noreferrer" className="h2-responsive white-text font-weight-bold">
                2 Hillview Rd, Eastwood NSW 2122
              </a>
            </div>  
            <h3 className="subtext-header h3-responsive mt-2 mb-4">
              Discover comprehensive healthcare at our medical center, offering a range of allied health and pathology 
              services all under one roof. Experience expert care and personalized treatment plans. 
              Visit us for integrated medical solutions tailored to your needs.
            </h3>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  )
}

export default Intro
