import React, { useEffect, useContext } from 'react'
import Intro from '../components/intro'
import SEO from '../components/seo'
import Carousel from '../components/carousel'
import Features from '../components/features'
import Card from '../components/card'
import { MDBRow } from 'mdbreact'
import Bulletin from '../components/bulletin'
import { useServiceData } from '../utils/useServiceData'
import Amplify from 'aws-amplify'
import config from '../aws-exports'
import { getUser, setUser, logout } from '../components/auth/app-user'
import { LoggedInContext } from '../components/context'
import img1 from "../images/home.jpg"
import img2 from "../images/waitingroom.jpg"
Amplify.configure(config)

const App = () => {
  const { allMarkdownRemark, allFile } = useServiceData()
  const {setLoggedIn} = useContext(LoggedInContext)

  const imgList = [
    {
      name: img1,
      key: "1"
    },
    {
      name: img2,
      key: "2"
    }
  ]  

  // Format the data for easy access
  const services = []
  const images = {}
  allFile.nodes.forEach(({id, childImageSharp}) => {
    images[id] = childImageSharp.gatsbyImageData
  })

  allMarkdownRemark.edges.forEach(({node}) => {
    const item = {
      key: node.frontmatter.sequence,
      title: node.frontmatter.title,
      tagline: node.frontmatter.tagline,
      image: images[node.frontmatter.cardimage.id],
      link: node.frontmatter.link,
      description: node.frontmatter.description
    }

    services.push(item)
  })

  useEffect(() => {
    //Clear booking patient and appointment cache
    const updatedUserInfo = getUser()
    if (updatedUserInfo.patientName) delete updatedUserInfo.patientName
    if (updatedUserInfo.patientId)  delete updatedUserInfo.patientId
    if (updatedUserInfo.drId) delete updatedUserInfo.drId
    if (updatedUserInfo.appId) delete updatedUserInfo.appId
    setUser(updatedUserInfo)
    
    //Log user out if logged in for more than 24 hours
    if (updatedUserInfo.loginTime) {
      const now = new Date()
      const sessionLength = now.getTime() - updatedUserInfo.loginTime
      if (sessionLength > 86400000) {
        logout()
        setLoggedIn(false)
      }
    } else {
      //Logged in from the version without auto logout
      logout()
      setLoggedIn(false)
    }
  }, [])

  return (
    <>
      <SEO 
        keywords={['Eastwood', 'medical centre', 'medical practice', 'GP', 'Aspire', 'online booking', 'bulk-bill', 'medical abortion', 'iron infusion',
        'physiotherapist', 'physio', 'dietitian', 'psychology', 'psychologist', 'EPC', 'GPMP/TCA', 'AGPAL', 'accredited', 'pathology', 'Douglass Hanley Moir']}
      />
      <Carousel imgList={imgList}/>
      <Intro />
      <main>
        <title>Aspire Medical Centre - Family-Friendly Health Services</title>
        <meta name="Visit Our Accredited Modern Medical Centre in Eastwood - GPs, Allied Health, and DHM Pathology Services All Under One Roof. 
        Just Minutes from Eastwood Train Station. Offering Bulk Billing and Walk-In Appointments. Experience Expert Care and Personalized Treatment Plans Today!"></meta> 
        <Features/>
        <section id="cardSection">
          <h2 className="h1-responsive text-center font-weight-bold mb-5">
            Our services
          </h2>
          <MDBRow className="m-0" center>
            {services.map((item) => 
              <Card 
                key={item.key}
                image={item.image}
                title={item.title}
                link={item.link}
                linkText={item.tagline}
                description={item.description}
              />
            )}
          </MDBRow>
        </section>
      </main>
      <Bulletin/>
    </>
  )
}

export default App